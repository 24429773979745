.header {
  background-color: #7fa0ea;
  padding: 1rem;
  font-weight: 100;
  font-size: 4rem;
  text-align: center;
  h1 {
    color: #34175a;
  }
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactPhone {
  display: flex;
  align-items: center;
  gap: 10px;
  i {
    display: block;
    font-size: 2.5rem;
  }
}

.stickToTop {
  position: sticky;
  top: 0px;
}
