:root {
  --mainFont: "Lato",sans-serif;
  --primary: #052942;
  --dark: #484848;
  --light: #fff;
  --warn: rgba(219,24,24,0.7176470588235294);
}

* {
  margin: 0;
  padding: 0;
}