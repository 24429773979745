.container {
  max-width: 75%;
  margin: 0 auto;
  display: flex;
  column-gap: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.content {
  line-height: 1.5;
  h2 {
    letter-spacing: 2px;
    font-size: 3.6rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  p {
    padding: 1rem 0;
    text-align: justify;
  }
}

.image {
  img {
    width: 600px;
    border-radius: 5px;
  }
}
