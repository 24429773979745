.footer {
  display: grid;
  row-gap: 10px;
  background-color: var(--primary);
  padding: 1rem;
  p {
    color: var(--light);
    text-align: center;
    font-size: 1rem;
  }
  position: sticky;
  bottom: 0;
}

.socials {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding: .75rem;
  i {
    color: var(--light);
    font-size: 20px;
  }
}